import Router from 'vue-router';
import Vue from 'vue';

Vue.use(Router)

const routes = [{
    path: "/",
    name: "index",
    component: () => import('./pages/index')
}, {
    path: "/member",
    name: "member",
    component: () => import('./pages/member'),
    redirect: "/member/article/list",
    children: [{
        path: "article/list",
        name: "article_list",
        meta: { title: "文章列表" },
        component: () => import('./components/member/ArticleList')
    }, {
        path: "article/add",
        name: "add_article",
        meta: { title: "写日志" },
        component: () => import('./components/member/AddArticle')
    }, {
        path: "reset-password",
        name: "reset_password",
        meta: { title: "重置密码" },
        component: () => import('./components/member/ResetPassword')
    }, {
        path: "nav",
        name: "nav",
        meta: { title: "顶部导航" },
        component: () => import('./components/member/Nav')
    },]
}, {
    path: "/article/:id",
    name: "article",
    component: () => import('./pages/article')
}]

const router = new Router({
    routes,
    mode: 'history'
})

router.beforeEach((to, from, next) => {
    if (to.name == 'member') {
        if (!localStorage.getItem('token')) {
            next('/')
        }
    }
    next();
})

export default router